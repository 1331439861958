import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
const Page = ({ data, location }) => {
  const params = new URLSearchParams(location.search);
  const hubspotID = params.get("hubspotID");

  const [iframe, setIframe] = useState("");

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (hubspotID !== null) {
        setIframe(`https://meetings.hubspot.com/${hubspotID}?embed=true`);
      } else {
        setIframe(
          "https://meetings.hubspot.com/dark-horse/website-round-robin?embed=true"
        );
      }
    }
  }, [iframe]);

  return (
    <Layout>
      <SearchEngineOptimization
      // title=""
      // description=""
      // openGraphImage={data.openGraphImage.publicURL}
      // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="md:pb-30 bg-secondary-100 pt-16 pb-22 md:pt-20">
        <div className="container">
          <div className="mx-auto max-w-3xl">
            <iframe
              src={iframe}
              title="Book a meeting"
              width="100%"
              height="660px"
              border="0"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
